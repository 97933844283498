<!--  -->
<template>
    <div class='com-avatar-dress flex-just-center' @click="$emit('click')">
        <div class="dress-body" :style="bodyStyle" v-if="bodyUrl"></div>
        <div class="dress-extend flex-center" v-if="headUrl" >
            <div class="extend-body" :style="headStyle"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            urls: {
                type: [String, Array],
                default(){
                    return []
                }
            }
        },
        data() {
            return {

            };
        },
        methods: {
            urlCheck(url, type){
                let name = url.substring(url.lastIndexOf('/') + 1, url.length) || ''
                return name.indexOf(type) > -1
            },
        },
        created() {

        },
        components: {
        },
        computed: {
            bodyStyle(){
                let {bodyUrl: url} = this
                if(!url) return {}
                return {
                    background: `url(${url}) no-repeat`,
                    backgroundSize: '100% 100%'
                }
            },
            headStyle(){
                let {headUrl: url} = this
                if(!url) return {}
                return {
                    background: `url(${url}) no-repeat`,
                    backgroundSize: '100% 100%'
                }
            },
            bodyUrl(){
                let {urls} = this
                let key = 'body'
                if(this._common.isString(urls) && this.urlCheck(urls, key)) return urls
                let data = null
                for(let i in urls){
                    if(!this.urlCheck(urls[i], key)) continue
                    data = urls[i]
                    break
                }
                return data
            },
            headUrl(){
                let {urls} = this
                let key = 'head'
                if(this._common.isString(urls) && this.urlCheck(urls, key)) return urls
                let data = null
                for(let i in urls){
                    if(!this.urlCheck(urls[i], key)) continue
                    data = urls[i]
                    break
                }
                return data
            },
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-avatar-dress{
        width: 69px;
        height: 90px;
        align-items: flex-end;
        position: relative;
        .background-img-max(url('@{assetsUrlV2_0}/avatar/c26.png'));
        .dress-body{
            width: 50px;
            height: 78px;
            margin-bottom: 4px;
            .background-img-max(url('@{assetsUrlV2_0}/avatar/c23.png'));
        }
        .dress-extend{
            position: absolute;
            bottom: 0px;
            right: -13px;
            width: 25px;
            height: 25px;
            .background-img-max(url('@{assetsUrlV2_0}/avatar/c27.png'));
            .extend-body{
                width: 17px;
                height: 17px;
                .background-img-max(url('@{assetsUrlV2_0}/avatar/c24.png'));
            }
        }
    }
</style>