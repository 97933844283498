<!--  -->
<template>
    <div class='com-avatar-room'>
        <div class="room-item a"></div>
        <div class="room-item b">
            <!-- <div class="b-img-ele a"></div> -->
        </div>
        <div class="room-item c"></div>
        <div class="room-item d"></div>
        <!-- <div class="room-item e"></div> -->
    </div>
</template>

<script>
    export default {
        name: '',
        props: {},
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-avatar-room{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        @az: 1;
        @bz: 3;
        @cz: 4;
        @dz: 2;
        .room-item.a{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: @footHeight;
            z-index: @az;
            .background-img-max(url('@{assetsUrlV2_0}/avatar/c1.png'));
        }
        .room-item.b{
            width: 40vw;
            height: 91vh;
            position: absolute;
            top: 0px;
            left: 42vw;
            z-index: @bz;
            .background-img-max(url('@{assetsUrlV2_0}/avatar/c6.png'));
            .b-img-ele.a{
                width: 9vw;
                height: 16vh;
                left: -7vw;
                top: 8.55vh;
                position: absolute;
                .background-img-max(url('@{assetsUrlV2_0}/avatar/c5.png'));
            }
        }
        .room-item.c{
            position: absolute;
            width: @rightWallWidth;
            height: @rightWallHeight;
            right: 0px;
            top: 0px;
            z-index: @cz;
            .background-img-max(url('@{assetsUrlV2_0}/avatar/c9.png'));
        }
        .room-item.d{
            width: @leftWallWidth;
            height: @leftWallHeight;
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: @dz;
            .background-img-max(url('@{assetsUrlV2_0}/avatar/c10.png'));
        }
        // .room-item.e{
        //     width: 46vw;
        //     height: 20vh;
        //     left: 0px;
        //     bottom: 0px;
        //     z-index: @dz;
        //     position: absolute;
        //     .background-img-max(url('@{assetsUrlV2_0}/avatar/c13.png'));
        // }
    }
</style>