<!--  -->
<template>
    <div class='page-avatar-summary'>
        <room></room>
        <div class="room-ele-a"></div>
        <div class="summary-item left flex-center">
            <div class="left-body">
                <!-- <div class="left-ele-a"></div> -->
                <div class="left-ele-b align-center-position"></div>
                <div class="left-ele-c align-center-position"></div>
                <div class="left-ele-d align-center-position flex-center">
                    <div class="d-avatar" v-if="userAvatar">
                        <avatarStatic :src="previewUrl"></avatarStatic>
                    </div>
                    <div class="d-foot"></div>
                    <div class="d-fun align-center-position">
                        <div class="fun-body cursor-pointer scale-button flex-center" @click="saveAvatarCon">
                            <span class="text-mini bold">{{buttonText}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="summary-item right">
            <div class="right-body">
                <div class="right-tab-box">
                    <div class="tab-item a flex-center bold cursor-pointer" v-for="v in widowTabs" :key="v.value"
                    :class="{'active': goodsType == v.value}"
                    @click="tabChange(v.value)"
                    >
                        <span class="text-normal">{{v.name}}</span>
                    </div>
                </div>
                <div class="right-con-box">
                    <dressBox 
                    v-show="tebSelected.value == 2"
                    @change="previewChangeDress" 
                    @listChange="listChange('dress', $event)"
                    @updateAvatarDress="updateAvatarDress"
                    :previewList.sync="previewList"
                    ref="dressBox" 
                    :avatarId.sync="previewAvatarId"
                    :avatarType.sync="avatarType"
                    :dressUrl.sync="dressUrl"
                    ></dressBox>
                    <avatarBox
                    @change="previewChangeAvatar" 
                    @listChange="listChange('avatar', $event)"
                    v-show="tebSelected.value == 1"
                    ref="avatarBox" 
                    ></avatarBox>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
    import room from '../components/room.vue'
    // import avatar from '@/views/user/components/avatar.vue'
    import userApiClass from '@/API/page/user'
    import avatarApiClass from '@/API/page/avatar'
    import dressBox from '../components/dressBox.vue'
    import avatarBox from '../components/avatarBox.vue'


    export default {
        name: '',
        props: {},
        data() {
            return {
                userApi: new userApiClass(),
                api: new avatarApiClass(),
                userInfo: {},
                widowTabs: [
                    {name: '形象', value: 1},
                    {name: '服装', value: 2},
                ],
                // windowTabVal: 1,
                previewData: {},
                previewList: {},
                goodsType: this.$route.params.goodsType,
                previewDataOld: null,
                dress_list: [],
                avatar_list: [],
                decorate_list: []
            };
        },
        methods: {
            // 冒泡排序
            async pageInit(){
                await this.userInfoInit()
                this.goodsTypeInit()
            },
            goodsTypeInit(){
                let {avatarName} = this.userInfoCul
                let goodsType = 2
                if(!avatarName) goodsType = 1
                if(goodsType == this.goodsType) return
                let {query} = this.$route
                this._routeHand.goto({name: 'avatar-summary', type: 'replace', params: {goodsType}, query})
            },
            async userInfoInit(opt){
                let res = await this.userApi.userAccount(opt)
                if(!res) return
                this.userInfo = res
            },
            tabChange(val){
                // this.windowTabVal = val
                let {query} = this.$route
                this._routeHand.goto({name: 'avatar-summary', type: 'replace', params: {goodsType: val}, query})
            },
            previewChange(type, data){
                this.previewData[type] = data
            },
            previewChangeDress(data){
                this.$set(this.previewData, 'dress', data)
            },
            previewChangeAvatar(data){
                this.$delete(this.previewData, 'dress')
                this.$set(this.previewData, 'avatar', data)
            },
            previewChangeDecorate(data){
                this.$set(this.previewData, 'decorate', data)
            },
            async saveAvatarCon(){
                let fun = this.tebSelected.purchaseFun
                if(!fun || !this[fun]) fun = 'saveAvatar'
                return this[fun]()
            },
            async saveAvatar(){
                let res = await this.saveNotice()
                if(!res) return
                this.saveAvatarAction()
            },
            async saveAvatarAction(){
                if(this.hasNonePreview) return
                let {dress = {}, avatar = {}} = this.previewData
                let state = false, resDress = true, resAvatar = true, resDecorate = true
                if(avatar.id) resAvatar = await this.api.purchaseAvatar({avatarId: avatar.id})
                if(!resAvatar) return
                if(dress.dressId) resDress = await this.api.purchaseDress({dressId: dress.dressId})
                // if(decorate.id) resDecorate = await this.api.purchaseDecorate({decorateId: decorate.id})

                if(resDress && resAvatar && resDecorate){
                    this._common.toastOpen({
                        message: this.TEXT.common['save success']
                    })
                    if(dress.dressId) this.$refs['dressBox'].refresh()
                    if(avatar.id) this.$refs['avatarBox'].refresh()
                    // if(decorate.id && this.$refs.decorateBox) this.$refs['decorateBox'].refresh()
                    this.userInfoInit({noLoad: true})
                    this.$refs['dressBox'].previewClear()
                    this.$refs['avatarBox'].previewClear()
                }
            },
            async saveNotice(){
                let message = '', confirm = false, subMessage = null
                if(this.hasNonePreview) message = this.pageText['none preview']
                else if(this.needPayDress || this.needPayAvatar){
                    message = this.pageText['purchase confirm']
                    let key = 'purchase confirm sub message'
                    if(this.needPayDress && !this.needPayAvatar) key += ' dress'
                    else if(!this.needPayDress && this.needPayAvatar) key += ' avatar'
                    else key += ' avatar dress'
                    subMessage = this.pageText[key]
                    subMessage = this._common.urlParse(subMessage, {dressPrice: this.needPayDress, avatarPrice: this.needPayAvatar})
                    // subMessage = this.purchaseSubMessage
                    confirm = true
                }else message = this.pageText['purchase confirm'], confirm = true
                let buttons = [
                    {title: '确认', label: 0, type: 'confirm'}
                ]
                if(confirm) buttons.unshift({title: '返回', type: 'cancel'})
                let confirmType = subMessage ? 'c3plus' : 'c3'
                let res = await this._common.confirmPro.open({
                    message,
                    subMessage,
                    buttons,
                    confirmType
                })
                res = res || {}
                if(!confirm) return false
                return res.label == 0
            },
            async purchaseConfirm(){
                let val = this.previewData
                let message = this.pageText['purchase confirm']
                let subMessage = null
                if(!val.purchaseState){
                    subMessage = this.pageText['purchase confirm sub message']
                    subMessage = this._common.urlParse(subMessage, {price: val.price})
                }
                let res = await this._common.confirmPro.open({
                    message,
                    subMessage,
                    buttons: [
                        {title: '返回', label: 1, type: 'cancel'},
                        {title: '确定', label: 0, type: 'confirm'},
                    ]
                })
                res = res || {}
                return res.label == 0
            },
            
            async previewCheck(){
                let {avatar = {}, dress} = this.previewData
                let avatarId = this.previewAvatarId
                if(!dress) return
                let dressId = dress.dressId
                let res = await this.api.avatarDress({avatarId, dressId})
                if(!res || !res.data){
                    // this.previewData = this._common.deepCopy(this.previewDataOld)
                    return this.previewDressCheck(res)
                } 
                let {data, code} = res
                let {avatarDressUrl} = data
                let url = null
                if(this._common.isObject(avatarDressUrl)) url = avatarDressUrl.static
                if(!url) return
                this.$set(this.previewList, `${avatarId}-${dressId}`, url)
            },
            previewDressCheck(res){
                let {code} = res
                let message = this.ERRORCODE.avatar[code]
                if(!message) return
                this._common.confirmPro.open({
                    message,
                    confirmType: 'c3',
                    buttons: [
                        {title: '确定', type: 'confirm'}
                    ]
                })
            },
            updateAvatarDress(res){
                let {data, avatarId, dressId} = res
                let {avatarDressUrl} = data
                let url = null
                if(this._common.isObject(avatarDressUrl)) url = avatarDressUrl.static
                if(!url) return
                this.$set(this.previewList, `${avatarId}-${dressId}`, url)
            },
            listChange(type, list){
                if(this[`${type}_list`] === undefined) return
                this[`${type}_list`] = list
            },
            previewTimtout(){
                this.$delete(this.previewData, 'decorate')
            },
            async decoratePurchase(){
                let res = await this.saveNoticeDecorate()
                if(!res) return
                this.saveAvatarActionDecorate()
            },
            async saveNoticeDecorate(){
                let {message, subMessage} = this.decorateNoticeMsg
                let {decorate = {}} = this.previewData
                let confirm = decorate.id ? true : false
                let buttons = [
                    {title: '确认', label: 0, type: 'confirm'}
                ]
                if(confirm) buttons.unshift({title: '返回', type: 'cancel'})
                let confirmType = subMessage ? 'c3plus' : 'c3'
                let res = await this._common.confirmPro.open({
                    message,
                    subMessage,
                    buttons,
                    confirmType
                })
                res = res || {}
                if(!confirm) return false
                return res.label == 0
            },
            async saveAvatarActionDecorate(){
                let {decorate} = this.previewData
                if(!decorate.id) return
                let res = await this.api.purchaseDecorate({decorateId: decorate.id})

                if(res){
                    this._common.toastOpen({
                        message: this.TEXT.common['save success']
                    })
                    if(this.$refs.decorateBox) this.$refs['decorateBox'].refresh()
                    this.userInfoInit({noLoad: true})
                    this.$refs['decorateBox'].previewClear()
                }
            }
        },
        created() {
            this.pageInit()
        },
        components: {
            room,
            // avatar,
            // winBtn,
            // dress
            dressBox,
            avatarBox,
        },
        computed: {
            decorateNoticeMsg(){
                let message = this.pageText['none preview decorate']
                let subMessage = null
                let {previewData: {decorate = {}} = {}} = this
                if(decorate.id){
                    message = this.pageText['purchase decorate confirm']
                    if(this.needPayDecorate){
                        subMessage = this.pageText['purchase decorate submessage']
                        subMessage = this._common.urlParse(subMessage, {price: decorate.price})
                    }
                    
                }
                return {message, subMessage}
            },
            buttonText(){
                let {tebSelected: {buttonText}} = this
                if(!buttonText) buttonText = '保存形象'
                return buttonText
            },
            purchaseSubMessage(){
                // let message = this.pageText['purchase confirm sub message more']
                let {needPayAvatar: avatar, needPayDress: dress} = this
                let price = [], amount = 0
                if(avatar) price.push(`${avatar}（形象）`), amount += avatar
                if(dress) price.push(`${dress}（服装）`), amount += dress
                if(!price.length) price = ''
                let currency = this.PARAMS.currency || ''
                let message = `共消费：${price.join('+')}`
                if(price.length > 1) message += `=${amount}`
                message += currency
                return message


            },
            preDecorateUrl(){
                let {previewData: list} = this
                let val = list.decorate || {}
                return val.decorateUrl
            },
            preDecorateId(){
                let {previewData: list} = this
                let val = list.decorate || {}
                return val.id
            },
            ownDecorateId(){
                let {decorateId} = this.userInfo
                return decorateId
            },
            isDecoratePreview(){
                if(!this.needPayDecorate) return false
                let {preDecorateId, ownDecorateId} = this
                return preDecorateId && preDecorateId != ownDecorateId
            },
            needPayDress(){
                let {dress = {}} = this.previewData
                return dress.price
            },
            needPayAvatar(){
                let {avatar = {}} = this.previewData
                return avatar.price
            },
            needPayDecorate(){
                let {decorate = {}} = this.previewData
                return decorate.price
            },
            hasNonePreview(){
                let {avatar = {}, dress = {}} = this.previewData
                if(!avatar.id && !dress.dressId) return true
                return false
            },
            userInfoCul(){
                let {userInfo, avatar_list, dress_list} = this
                userInfo = this._common.deepCopy(userInfo)
                let {avatarName, dressName} = userInfo
                let avatarId = null, dressId = null
                for(let i in avatar_list){
                    let {avatarName: name, id} = avatar_list[i]
                    if(name == avatarName){
                        avatarId = id
                        break
                    }
                }
                for(let i in dress_list){
                    let {dressName: name, id} = dress_list[i]
                    if(name == dressName){
                        dressId = id
                        break
                    }
                }
                if(avatarId !== null) userInfo.avatarId = avatarId
                if(dressId !== null) userInfo.dressId = dressId
                return userInfo
            },
            previewAvatarId(){
                let {avatar = {}} = this.previewData
                let {id: avatarId} = avatar
                if(!avatarId) avatarId = this.userInfoCul.avatarId
                return avatarId
            },
            previewDressId(){
                let {dress = {}} = this.previewData
                let {dressId} = dress
                if(!dressId) dressId = this.userInfoCul.dressId
                return dressId
            },
            previewUrl(){
                let {dress = {}, avatar = {}} = this.previewData
                let {dressId} = dress, {id: avatarId} = avatar
                let {previewList: list} = this
                let url = null
                if(!avatarId) avatarId = this.previewAvatarId
                if(list[`${avatarId}-${dressId}`]) url = list[`${avatarId}-${dressId}`]
                else if(!dressId && avatarId && this._common.isObject(avatar.avatarUrl)) url = avatar.avatarUrl.static
                else {
                    let data = this.userInfoCul.avatarDressUrl
                    if(this._common.isObject(data)) url = data.static
                    else url = data
                }
                return url
            },
            tebSelected(){
                let {widowTabs: list, goodsType: val} = this
                let rd = {}
                for(let i in list){
                    if(list[i].value == val){
                        rd = list[i]
                        break
                    }
                }
                return rd
            },
            dressUrl(){
                let {userInfoCul} = this
                return userInfoCul.dressUrl
            },
            avatarType(){
                let {userInfoCul} = this
                return userInfoCul.avatarType
            },
            hasNoDress(){
                return this.previewData.id ? false : true
            },
            userAvatar(){
                let {userInfoCul} = this
                return userInfoCul.avatarDressUrl
            },
        },
        watch: {
            $route: {
                handler(val){
                    this.goodsType = val.params.goodsType
                },
                deep: true,
                immediate: true
            },
            previewData: {
                handler(val, oldVal){
                    this.previewDataOld = oldVal
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .page-avatar-summary{
        display: flex;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        @eleZ: 3;
        .room-ele-a{
            width: 9vw;
            height: 16vh;
            left: 35vw;
            top: 8.5vh;
            position: absolute;
            .background-img-max(url('@{assetsUrlV2_0}/avatar/c5.png'));
            z-index: @eleZ;
        }
        .summary-item{
            position: relative;
            height: 100vh;
        }
        .summary-item.left{
            // width: @leftWallWidth;
            width: 41.7vw;
            height: @leftWallHeight;
            .left-body{
                width: 254px;
                height: 273px;
                div[class^='left-ele']{
                    position: absolute;
                }
                // .left-ele-a{
                //     top: 0px;
                //     left: 0px;
                //     width: 100%;
                //     height: 100%;
                //     .background-img-max(url('@{assetsUrlV2_0}/avatar/c2.png'));
                // }
                .left-ele-b{
                    width: 254px;
                    height: 273px;
                    bottom: -1px;
                    z-index: 2;
                    .background-img-max(url('@{assetsUrlV2_0}/avatar/c12.png'));
                }
                .left-ele-d{
                    width: 123px;
                    height: 277px;
                    bottom: calc(-29px - 13px);
                    z-index: 2;
                    display: flex;
                    align-items: flex-end;
                    .d-foot{
                        width: 100%;
                        height: 29px;
                        position: absolute;
                        bottom: 0px;
                        .background-img-max(url('@{assetsUrlV2_0}/avatar/c14.png'));
                    }
                    .d-avatar{
                        margin-bottom: 22px;
                        // margin-left: 16px;
                        z-index: 2;
                        position: relative;
                        .com-global-avatar-static{
                            img{
                                height: 255px;
                                width: auto;
                            }
                        }
                    }
                    .d-fun{
                        position: absolute;
                        width: 82px;
                        white-space: nowrap;
                        height: 22px;
                        bottom: -11px;
                        .fun-body{
                            width: 100%;
                            height: 100%;
                            .background-img-max(url('@{assetsUrlV2_0}/avatar/c15.png'));
                        }
                        span{
                            color: #BE7D18;
                        }
                    }
                }
                // .left-ele-c{
                //     width: 255px;
                //     height: 271px;
                //     bottom: 5px;
                //     z-index: 1;
                //     .background-img-max(url('@{assetsUrlV2_0}/avatar/c4.png'));
                // }
                
            }
        }
        .summary-item.right{
            position: absolute;
            width: @rightWallWidth;
            height: @rightWallHeight;
            top: 0px;
            right: 0px;
            .right-body{
                position: absolute;
                left: 2vw;
                bottom: 3.5vh;
            }
                .right-tab-box{
                    position: absolute;
                    left: 10px;
                    top: -23px;
                    height: 27px;
                    z-index: 1;
                    display: flex;
                    .tab-item{
                        width: 71px;
                        height: 24px;
                        color: #B67738;
                        .background-img-max(url('@{assetsUrlV2_0}/avatar/c20.png'));
                    }
                    .tab-item.active{
                        color: #B37435;
                        height: 100%;
                        .background-img-max(url('@{assetsUrlV2_0}/avatar/c19.png'));
                    }

                }
                .right-con-box{
                    width: 338px;
                    height: 273px;
                    .background-img-max(url('@{assetsUrlV2_0}/avatar/c16.png'));
                }
            // }
        }
    }
</style>