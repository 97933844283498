<!--  -->
<template>
    <div class="com-avatar-dress-box">
        <div class="dress-win-body mini-scrollbar max-height">
            <div class="dre-row" v-for="(s, i) in dressListCul" :key="i">
                <div class="row-item a">
                    <div class="row-a-item" v-for="v in s" :key="v.id">
                        <dress :urls="v.dressUrl" class="scale-button" @click="preview(v)"></dress>
                        <div class="dre-btn align-center-position">
                            <winBtn class="normal-button">
                                <span class="text-small bold" v-if="v.purchaseState">{{v.purchaseState}}</span>
                                <div class="purchase-box flex-center" v-else>
                                    <div class="price-icon"></div>
                                    <span class="text-small bold">{{v.price}}</span>
                                </div>
                            </winBtn>
                        </div>
                    </div>
                </div>
                <div class="row-item b">
                    <div class="b-body"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import dress from './dress.vue'
    import winBtn from './winBtn.vue'
    import apiClass from '@/API/page/avatar'

    export default {
        name: '',
        props: {
            avatarType: [Number, String],
            dressUrl: [String, Array],
            avatarId: [Number, String],
            previewList: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data() {
            return {
                api: new apiClass(),
                widowTabs: [
                    {name: '形象', value: 1},
                    {name: '服装', value: 2},
                ],
                windowTabVal: 1,
                dressList: [],
                maxNum: 3,
                previewData: {},
            };
        },
        methods: {
            pageInit(){
                this.dataLoad()
            },
            async dataLoad(opt){
                let res = await this.api.dressList(opt)
                if(!res) return
                this.dressList = res
            },
            tabChange(val){
                this.windowTabVal = val
            },
            purchaseState(val){
                let {price} = val || {}
                let text = this.TEXT.components['dress purchased']
                return price <= 0 ? text : null
            },
            async purchaseDress(val){
                let {purchaseState} = val
                if(purchaseState) return
                let res = await this.purchaseConfirm()
                if(!res) return
                return this.purchaseAction(res)
            },
            async purchaseConfirm(){
                let res = await this._common.confirmPro.open({
                    message: ''
                })
            },
            purchaseAction(){},
            async avatarDressCheck(val){
                let {dressId} = val
                let {avatarId} = this
                if(!avatarId) return true
                if(this.previewList[`${avatarId}-${dressId}`]) return true
                let res = await this.api.avatarDress({avatarId, dressId})
                if(res && res.data){
                    this.$emit('updateAvatarDress', {avatarId, dressId, data: res.data})
                    return true
                }
                let {code} = res || {}
                let message = this.ERRORCODE.avatar[code]
                if(message) this._common.confirmPro.open({
                    message,
                    confirmType: 'c3',
                    buttons: [
                        {title: '确定', type: 'confirm'}
                    ]
                })
                else this.api._errorHand.hand(res)()
                return false
            },
            async preview(val){
                let res = await this.avatarDressCheck(val)
                if(!res) return
                let {dressId: id, dressUrl: urls} = val || {}
                this.previewData = val
            },
            refresh(){
                this.dataLoad({noLoad: true})
            },
            previewClear(){
                this.previewData = {}
            }
        },
        created() {
            this.pageInit()
        },
        components: {
            dress,
            winBtn
        },
        computed: {
            previewId(){
                let {previewData: {id}} = this
                return id
            },
            dressListCul(){
                let {dressList: list, maxNum} = this
                let rd = [], data = []
                for(let i in list){
                    if(data.length >= maxNum){
                        rd.push(this._common.deepCopy(data))
                        data = []
                    }
                    let {dressUrl} = list[i]
                    dressUrl = dressUrl || ''
                    try{
                        dressUrl = dressUrl.split(',')
                    }catch(e){
                        dressUrl = []
                    }
                    let purchaseState = this.purchaseState(list[i])
                    data.push({
                        ...list[i],
                        dressUrl,
                        purchaseState,
                    })
                }
                if(data.length) rd.push(data)
                return rd
            }
        },
        watch: {
            previewData: {
                handler(val){
                    this.$emit('change', val)
                },
                immediate: true,
                deep: true
            },
            dressList: {
                handler(val){
                    this.$emit('listChange', val)
                },
                immediate: true,
                deep: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-avatar-dress-box{
        // width: 338px;
        // height: 273px;
        // padding: 10px;
        width: 100%;
        height: 100%;
        padding: 10px;
        // .background-img-max(url('@{assetsUrlV2_0}/avatar/c16.png'));
        .dre-row{
            z-index: 2;
            width: 100%;
            height: 128px;
            
            .row-item.a{
                width: 100%;
                height: 90px;
                margin-bottom: -2px;
                padding: 0px 16px;
                display: flex;
                // justify-content: space-between;
                .row-a-item+ .row-a-item{
                    margin-left: 38px;
                }
                .row-a-item{
                    width: 69px;
                    height: 100%;
                    position: relative;
                    .background-img-max(url('@{assetsUrlV2_0}/avatar/c26.png'));
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    .dre-content{
                        width: 50px;
                        height: 79px;
                        margin-bottom: 2px;
                        position: relative;
                        .content-a{
                            width: 100%;
                            height: 100%;
                            .background-img-max(url('@{assetsUrlV2_0}/avatar/c23.png'));
                        }
                        .content-b{
                            position: absolute;
                            width: 25px;
                            height: 25px;
                            bottom: -2px;
                            right: -22px;
                            .background-img-max(url('@{assetsUrlV2_0}/avatar/c27.png'));
                            .cb-content{
                                width: 17px;
                                height: 17px;
                                .background-img-max(url('@{assetsUrlV2_0}/avatar/c24.png'));
                            }
                        }
                    }
                    .dre-btn{
                        position: absolute;
                        bottom: -33px;
                        .purchase-box{
                            height: 100%;
                            .price-icon{
                                width: 20px;
                                height: 20px;
                                margin-right: 2px;
                                .background-img-max(url('@{assetsUrlV2_0}/avatar/c25.png'));
                            }
                        }
                    }
                }
            }
            .row-item.b{
                height: 43px;
                width: 100%;
                margin-bottom: -5px;
                .background-img-max(url('@{assetsUrlV2_0}/avatar/c17.png'));
                .b-body{
                    width: 307px;
                    height: 34px;
                    margin: 2px auto 0px;
                    .background-img-max(url('@{assetsUrlV2_0}/avatar/c18.png'));
                    
                }
            }
        }
        .right-tab-box{
            position: absolute;
            left: 10px;
            top: -23px;
            height: 27px;
            z-index: 1;
            display: flex;
            .tab-item{
                width: 71px;
                height: 24px;
                color: #B67738;
                .background-img-max(url('@{assetsUrlV2_0}/avatar/c20.png'));
            }
            .tab-item.active{
                color: #B37435;
                height: 100%;
                .background-img-max(url('@{assetsUrlV2_0}/avatar/c19.png'));
            }

        }
    }
</style>