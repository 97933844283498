<!--  -->
<template>
    <div class='com-avatar-btn cursor-pointer scale-button' @click="$emit('click')">
        <div class="btn-content flex-center">
            <slot class="blod"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {},
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-avatar-btn{
        width: 67px;
        height: 31px;
        position: relative;
        .background-img-max(url('@{assetsUrlV2_0}/avatar/c28.png'));
        .btn-content{
            color: #9A531F;
            position: absolute;
            top: 1px;
            left: 2px;
            width: 61px;
            height: 26px;
            .background-img-max(url('@{assetsUrlV2_0}/avatar/c21.png'));
        }
    }
</style>